<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav
          class="
            navbar navbar-expand-md navbar-dark
            px-0
            pr-sm-3
            navbar-offcanvas
          "
        >
          <a
            class="btn px-0 d-block d-md-none"
            data-toggle="modal"
            data-target="#kalenderModal"
            title="Kalender PO"
          >
            <i class="fa fa-calendar text-light"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <img
              :src="state.logo"
              alt="Logo"
              height="30"
              class="brand-logo mt-1"
            />
          </span>

          <Menu>
            <ul class="navbar-nav w-100 pl-3 pl-md-0">
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/lisensi"
                  class="nav-link bg-yellowish text-main"
                  title="Lisensi (Free trial 5 hari lagi)"
                  >Lisensi<span class="d-md-none">
                    (Free trial 5 hari lagi)</span
                  ></router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/produk-group"
                  class="nav-link"
                  title="Grup Produk"
                  >Produk Grup</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a href="./jual-stok.html" class="nav-link" title="Grup Produk"
                  >Stok Bahan</a
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/supplier"
                  class="nav-link"
                  title="Supplier"
                  >Supplier</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/pelanggan/reseller"
                  class="nav-link"
                  title="Reseller"
                  >Reseller</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1 d-none">
                <a href="./jual-chat.html" class="nav-link" title="Chat"
                  >Chat</a
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/penjualan"
                  class="nav-link"
                  title="Rekap Penjualan"
                  >Penjualan</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link to="/jual/bantuan" class="nav-link" title="Chat"
                  >Bantuan</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/pengaturan"
                  class="nav-link"
                  title="Pengaturan"
                  >Pengaturan</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <router-link
                  to="/jual/notifikasi"
                  class="nav-link"
                  title="Notifikasi"
                  >Notifikasi
                  <span class="badge badge-pill badge-secondary align-top"
                    >1</span
                  ></router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a href="#" @click="logout" class="nav-link" title="Logout"
                  >Logout</a
                >
              </li>
            </ul>
          </Menu>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url(' + state.imageBaner + ');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.namaToko }}</p>
            </div>
          </div>
          <div class="row mt-3 mb-2 d-none">
            <div class="col-12">
              <input
                type="text"
                class="form-control"
                id="deliveryX"
                placeholder="Masukkan tanggal..."
                name="delivery"
                value=""
                required
              />
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
          <div class="row horizontal-menu-100 mb-3">
          <div class="col-12 px-0">
            <div class="list-group list-group-horizontal text-center">
              <a href="javascript:void(0);" class="list-group-item flex-fill on">PRE ORDER</a>
              <router-link :to="'/jual/pesanan-nonPo/info/' + state.nonPo.id" class="list-group-item flex-fill">READY STOCK</router-link>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="row mb-2">
              <div class="col-12">
                <select
                  class="form-control form-control-sm"
                  v-model="state.status"
                >
                  <option value="">Semua...</option>
                  <option value="aktif">Aktif</option>
                  <option value="inaktif">Inaktif</option>
                  <option value="selesai">Selesai</option>
                  <option value="batal">Batal</option>
                </select>
              </div>
              <!-- .col -->
            </div>

            <div class="input-group input-group-sm my-3">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Cari..."
                  v-model="state.search"
                />
                <span class="input-group-append">
                  <button type="submit" class="btn btn-outline-main">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </form>

          <div class="small text-truncate">Daftar PO Grup:
            <router-link to="/jual/pesanan-group/tambah" class="btn btn-sm btn-outline-main py-0 float-right"><i class="fa fa-object-group mr-2"></i>Tambah PO Grup</router-link>
            <div class="clearfix"></div>
          </div>

          <div id="resultList" class="row mt-3 mb-5" v-if="state.finish">
            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              v-show="state.isNonPoShow"
            >
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-75 p-2 pl-3">
                    <router-link
                      :to="'/jual/pesanan-nonPo/info/' + state.nonPo.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >#Non-PO</router-link
                    >
                    <p class="card-text mb-1 text-truncate">
                      {{ state.nonPo.pembeli }} pembeli,
                      {{ state.nonPo.jumlah }} pcs,
                      <span class="currency"
                        >Rp. {{ changeMoney(state.nonPo.total) }}</span
                      >
                    </p>
                    <p class="card-text mb-0 text-truncate">
                      <small class="text-muted">Ready Stock</small>
                    </p>
                  </div>
                  <div class="float-right p-2 pl-2 w-25 border-left">
                    <div class="ml-1">
                      <p class="card-text mb-0 small text-muted mt-1">Status</p>
                      <span
                        class="badge align-top"
                        :class="
                          state.nonPo.status === 1
                            ? 'badge-secondary'
                            : 'badge-danger'
                        "
                        >{{
                          state.nonPo.status == 1 ? "Aktif" : "Inaktif"
                        }}</span
                      >
                      <p class="card-text mb-n1 small text-muted mt-1">
                        Terkirim
                      </p>
                      <span>0</span>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->

            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              v-for="group in state.poGroup"
              :key="group.id"
            >
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-75 p-2 pl-3">
                    <router-link
                      :to="'/jual/pesanan-group/info/' + group.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ group.name }}</router-link
                    >

                    <p class="card-text mb-1 text-truncate">
                      {{ group.pesanan_count }} pembeli,
                      {{ sumJumlah(group.pesanan) }} pcs,
                      <span class="currency"
                        >Rp. {{ sumTotal(group.pesanan) }}</span
                      >
                    </p>
                    <p class="card-text mb-0 text-truncate">
                      <small class="text-muted">{{
                        formatDate(group.tanggal_pengiriman)
                      }}</small>
                    </p>
                  </div>
                  <div class="float-right p-2 pl-2 w-25 border-left">
                    <div class="ml-1">
                      <p class="card-text mb-0 small text-muted mt-1">Status</p>
                      <span
                        class="badge align-top"
                        :class="
                          group.status === 1
                            ? 'badge-secondary'
                            : 'badge-danger'
                        "
                        >{{ group.status == 1 ? "Aktif" : "Inaktif" }}</span
                      >
                      <p class="card-text mb-n1 small text-muted mt-1">
                        Terkirim
                      </p>
                      <span>{{ sumTerkirim(group.pesanan) }}</span>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <!-- <router-link
            to="/jual/pesanan-group/tambah"
            id="btn-float"
            class="
              bg-light
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
              btn-float-second
            "
            ><i class="fas fa-plus mt-3" style="font-size: 25px"></i
          ></router-link> -->
          <router-link
            to="/jual/pesanan/tambah"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
          >
            <div class="d-flex justify-content-center mt-3">
              <i class="fas fa-cart-plus" style="font-size: 25px"></i>
            </div>
          </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import Menu from "../../../components/menu/Menu.vue";
import { reactive, onMounted } from "vue";
import LogoImg from "../../../assets/img/logo-invert.png";
import LogoBaner from "../../../assets/img/ca2.jpg";
import { useStore } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    const state = reactive({
      logo: LogoImg,
      poGroup: {},
      imageBaner: LogoBaner,
      namaToko: "Nama Toko",
      search: "",
      status: "",
      nonPo: {},
      finish: false,
      isSearch: false,
      isNonPoShow: false,
    });
    const logout = () => {
      store.dispatch("auth/logout");
    };
    const getNonPo = async () => {
      await axios.get("/api/seller/getNonPo").then((res) => {
        if (res.data.success) {
          state.nonPo = res.data.data;
        }
      });
    };
    const loadData = async () => {
      await axios.get("/api/seller/po-group").then((res) => {
        if (res.data.success) {
          state.poGroup = res.data.group;
          if (res.data.image !== "" && res.data.image !== null) {
            state.imageBaner = process.env.VUE_APP_API_URL + res.data.image;
          }
          if (res.data.toko_name !== "") {
            state.namaToko = res.data.toko_name;
          }
          state.finish = true;
        }
      });
    };
    onMounted(() => {
      getNonPo();
      loadData();
    });
    const formatDate = (dt) => {
      return moment(dt).format("dddd, DD MMMM YYYY");
    };
    const sumJumlah = (pesanan) => {
      let ret = 0;
      pesanan.map((pes) => {
        pes.detail.map((detail) => {
          ret += detail.jumlah;
        });
      });
      return ret;
    };
    const sumTotal = (pesanan) => {
      let ret = 0;
      pesanan.map((pes) => {
        pes.detail.map((detail) => {
          ret += Number(detail.total);
        });
      });
      let cur = Math.ceil(Number(ret));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return mo.split(".")[0];
    };
    const searchData = () => {
      axios
        .get(
          "/api/seller/po-group/search?search=" +
            state.search +
            "&status=" +
            state.status
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              state.poGroup = res.data.data;
            } else {
              state.poGroup = {};
            }
          }
        });
      state.isSearch = true;
    };
    const sumTerkirim = (pesanan) => {
      let ret = 0;
      pesanan.map((pes) => {
        if (pes.terkirim) {
          ret += 1;
        }
      });
      return ret;
    };
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    return {
      state,
      logout,
      formatDate,
      sumJumlah,
      sumTotal,
      searchData,
      sumTerkirim,
      changeMoney,
    };
  },
  components: {
    Menu,
  },
};
</script>